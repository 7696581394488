import { FC } from "react";
import { PageLayout } from "../layouts/page-layout";
import { useCarsModelsQuery } from "../api/use-cars-models-query";
import { Card, Progress, Typography, Button } from "@material-tailwind/react";
import { TRANSMISSIONS } from "./constants";
import { Link } from "react-router-dom";

const TABLE_HEAD = ["Машина", "Год", "Трансмиссия", ""];

export const Models: FC = () => {
    const { data: models = [], isLoading } = useCarsModelsQuery()
    if (isLoading) return <Progress/>

    return <PageLayout title="Модели" actions={(
      <Link to="/models/create">
        <Button>Создать</Button>
      </Link>
    )}>
        <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {models.map(({ _id, slug, name, year, transmission }, index) => {
            const isLast = index === models.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
 
            return (
              <tr key={_id}>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {name.ru}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {year}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {TRANSMISSIONS[transmission]}
                  </Typography>
                </td>
                <td className={classes}>
                    <Link to={`/models/${_id}`}>
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-medium"
                    >
                        Редактировать
                    </Typography>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
        </Card>
    </PageLayout>
}