import { useMutation } from '@tanstack/react-query';
import { Order, cancelOrder } from './toprent-api';

export const useCancelOrderMutation = () => {
    return useMutation({
        mutationFn: async (orderId: string): Promise<Order> => {
            const { data } = await cancelOrder(orderId);

            return data;
        }
    });
};
