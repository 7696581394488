import { FC, useState } from "react";
import { PageLayout } from "../layouts/page-layout";
import { useOrdersQuery } from "../api/use-orders-query";
// import { useServicesQuery } from "../api/use-services-query";
import { format } from "date-fns";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import { OrderStatus } from "../api/toprent-api";
import { useCrmCarsQuery } from "../api/use-crm-cars-query";
import { enqueueSnackbar } from 'notistack'
import { Button, Input } from "@material-tailwind/react";
import { useCancelOrderMutation } from "../api/use-cancel-order-mutation";
import { Pagination } from "../components/pagination";
import { useSearchParams } from "react-router-dom";
import { CancelDialog } from "./components/cancel-dialog";

const STATUSES: Record<OrderStatus, { color: string; label: string}> = {
    CREATED: { label: "Ожидание предоплаты", color: 'gray' },
    PAID: { label: "В работе у менеджера", color: 'green' },
    CANCELED: { label: "Отменен", color: 'red' },
}

export const Orders: FC = () => {
    // const { data: services } = useServicesQuery()
    const { data: cars } = useCrmCarsQuery()
    const { mutateAsync: cancelOrder } = useCancelOrderMutation()
    const [searchParams, setSearchParams] = useSearchParams()
    const [canceledOrderId, setCanceledOrderId] = useState<string>()
    const page = searchParams.get('page') || 1;
    const searchBidNumber = searchParams.get('bidNumber') || '';
    const { data: orders } = useOrdersQuery({ page: `${page}`, bidNumber: `${searchBidNumber}` })


    const handleOpenCancel = async (orderId: string) => {
        setCanceledOrderId(orderId);
    }
    
    const handleCancel = async (orderId: string) => {
        try {
            await cancelOrder(orderId)
            setCanceledOrderId(undefined)
            enqueueSnackbar({ variant: 'success', message: 'Отменен' });
            window.location.reload();
        } catch {
            enqueueSnackbar({ variant: 'error', message: 'Ошибка отмены' });
        }
    }

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text).then(function() {
            enqueueSnackbar({ variant: 'success', message: 'Текст успешно скопирован в буфер обмена' });
        }, function(err) {
            enqueueSnackbar({ variant: 'error', message: 'Произошла ошибка при копировании текста' });
        });
    }

    const handleChangePage = (key: string, value: unknown) => {
        setSearchParams((params) => {
            if (value) params.set(key, `${value}`);
            if (!value) params.delete(key);
            return params;
        })
    }
    
    return (
        <PageLayout title="Заявки">
            <div className="my-4 w-64 max-w-full">
                <Input value={searchBidNumber} crossOrigin={undefined} onChange={(e) => handleChangePage('bidNumber', e.target.value)} label="Номер заявки" />
            </div>
            <div className="grid grid-cols-3 gap-4 xl:grid-cols-1">
            {orders?.list?.map((item) => {
                 const currentCar = cars?.find((carItem) => carItem.carId === item.carId )

                return (
                <div key={item._id} className="rounded-lg bg-gray-50 mb-4 overflow-hidden">
                    <div className="p-5">
                        <div className="flex flex-col items-start">
                            <div className="flex justify-between w-full items-center mb-5">
                                <p className="text-base font-bold">№ {item.bidNumber}</p>
                                <p className="font-bold" style={{ color: STATUSES[item.status].color }}>{STATUSES[item.status].label}</p>
                            </div>

                            {item.begin && item.end && (
                                <p className="flex items-center text-base">
                                    <p>{format(new Date(item.begin.replaceAll('.', '/')), 'dd.MM.yyyy HH:mm')}</p>
                                    <ArrowLongRightIcon className="w-6 h-6 mx-2"/> 
                                    <p>{format(new Date(item.end.replaceAll('.', '/')), 'dd.MM.yyyy HH:mm')}</p>
                                </p>
                            )}

                        </div>

                        <div className="mt-4">
                            <p><span className="font-bold">Водитель:</span> {`${item.surname} ${item.name}`}</p>
                            <p><span className="font-bold">Автомобиль:</span> {currentCar?.model} - {currentCar?.number}</p>
                            <p><span className="font-bold">Номер телефона:</span> {item.phone}</p>
                            <div>
                            <p className="mt-3 mb-2 text-lg font-bold">Опции</p>
                            {item.services?.map((serviceItem) => (
                                <div key={serviceItem.name}>
                                <p className="text-sm mt-1">{serviceItem.name} - {serviceItem.cost} ₽</p>
                                </div>
                            ))}
                            </div>
                        </div>

                        <div className="font-medium mt-4">
                            <div>Предоплата: {item.prepayment} ₽</div> 
                            <div>Бронирование: {item.rentCost} ₽</div> 
                            <div>Итого: {item.cost} ₽</div> 
                            {item.status === 'PAID' && (
                                <div>Осталось заплатить: {item.cost - item.prepayment} ₽</div> 
                            )}
                            <div className="bg-black w-full h-[1px] my-2" />
                            {item.comment && (
                                <div>Отзыв: {item.comment}</div> 
                            )}
                            {!item.comment && (
                                <div className="text-light-blue-900" onClick={() => handleCopy(`https://toprent.ru/after-pay/${item._id}/${item.userId}`)}>Ссылка для обратной связи</div> 
                            )}
                        </div>

                        {item.status === 'PAID' && (
                            <div className="mt-6">
                                <Button onClick={() => handleOpenCancel(item._id)}>Вернуть предоплату и отменить</Button>
                            </div>
                        )}
                    </div>
                </div>
                )})}
            </div>
            <div className="mt-4">
                <Pagination pagesCount={orders?.pagesCount} active={Number(page)} handleChange={(page) => handleChangePage('page', page)} />
            </div>
            {canceledOrderId && (
                <CancelDialog open={Boolean(canceledOrderId)} onCancel={() => setCanceledOrderId(undefined)} onConfirm={() => handleCancel(canceledOrderId)} />
            )}
        </PageLayout>
    )
}