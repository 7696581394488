import React, { FC } from "react";
import {
  Button,
  Dialog as MDialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
 
export const CancelDialog: FC<{
    open?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
}> = ({ open = false, onCancel = () => {}, onConfirm = () => {} }) => {
  return (
    <>
      <MDialog open={open} handler={onCancel} size="xs">
        <DialogHeader>Отмена бронирования</DialogHeader>
        <DialogBody>
          Вы уверены, что хотите отменить бронирование и вернуть средства ?
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={onCancel}
            className="mr-1"
          >
            <span>Нет</span>
          </Button>
          <Button variant="gradient" color="green" onClick={onConfirm}>
            <span>Да</span>
          </Button>
        </DialogFooter>
      </MDialog>
    </>
  );
}