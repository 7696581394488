import { useQuery } from '@tanstack/react-query';
import {  Service, getWashing } from './toprent-api';

export const ORDER_QUERY_KEY = 'order';

export const useWashingQuery = () => {
    return useQuery<Service[]>({
        queryKey: ['get-washing'],
        queryFn: async () => {
            const response = await getWashing();

            return response.data.services;
        },
    });
};
