import { useMutation } from '@tanstack/react-query';
import { CarsModel, ReqCarsModel, createCarsModel } from './toprent-api';

export const useCreateModelsCarMutation = () => {
    return useMutation({
        mutationFn: async (carsModel: ReqCarsModel): Promise<CarsModel> => {
            const { data } = await createCarsModel(carsModel);

            return data;
        },
    });
};
