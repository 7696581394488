import { useQuery } from '@tanstack/react-query';
import { AuthProfile, getAuthProfile } from './toprent-api';

export const ORDER_QUERY_KEY = 'order';

export const useAuthProfileQuery = () => {
    return useQuery<AuthProfile>({
        queryKey: ['get-auth-profile'],
        queryFn: async () => {
            const response = await getAuthProfile();

            return response.data;
        },
    });
};
