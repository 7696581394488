import { FC } from "react";
import { File } from "../api/toprent-api";
import { useUploadFileMutation } from "../api/use-files";

export type UploaderProps = {
    onUpload?: (files: File[]) => void;
    path: string;
    modelId: string;
}

export const Uploader: FC<UploaderProps> = ({ onUpload = () => {}, path, modelId }) => {
    const { mutateAsync: uploadFile } = useUploadFileMutation(modelId);
    
    const handleChange = async (files: FileList | null) => {
        if (!files) return;
        const formData = new FormData();
        for (var x = 0; x < files.length; x++) {
            formData.append("files", files[x]);
        }
        formData.append("path", path);
        const data = await uploadFile(formData)
        onUpload(data)
    };

    return (
        <label className="border-dashed border-2 border-gray-500 flex justify-center items-center rounded-xl cursor-pointer">
            <div className="py-8">
                Выберите файлы для загрузки
            </div>
            <input type="file" name="files" multiple id="fileToUpload" hidden onChange={(e) => {
                handleChange(e.target.files);
                e.target.value = '';
            }} />
        </label>
    )
};
