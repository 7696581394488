import { useQuery } from '@tanstack/react-query';
import {  Car, CrmCar, getCrmCars } from './toprent-api';

export const ORDER_QUERY_KEY = 'order';

const mapFromDto = (cars: CrmCar[]): Car[] => {
    return cars.map((item) => ({
        ...item,
        cityId: item.city_id,
        carId: item.car_id,
    }));
}

export const useCrmCarsQuery = () => {
    return useQuery<Car[]>({
        queryKey: ['get-crm-cars'],
        queryFn: async () => {
            const response = await getCrmCars();

            const cars = mapFromDto(response.data.cars);
            
            return cars;
        },
    });
};
