import { useMutation } from '@tanstack/react-query';
import { Post, updatePost } from './toprent-api';

export const useUpdatePostMutation = (id: string) => {
    return useMutation({
        mutationFn: async (post: Post): Promise<Post> => {
            const { data } = await updatePost(id, post);

            return data;
        }
    });
};
