import { FC } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { v4 as uuidv4 } from 'uuid';
import { uploadFile } from "../../api/toprent-api";

type TextEditProps = {
    value: string;
    onChange: (value: string) => void
}

export const TextEdit: FC<TextEditProps> = ({ value, onChange }) => {
    return (
        <div className="border-2 border-gray-500 rounded-xl">
         <Editor
                apiKey="mic27zry7lic36zbbixl861udiuxi8xwvyf23o27yqwhe0ik"
                onEditorChange={onChange}
                value={value}
                init={{
                    height: 400,
                    menubar: 'insert',
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    images_upload_handler: blobInfo => {
                        return new Promise(async (resolve, reject) => {
                            const formData = new FormData();
                            formData.append("files", blobInfo.blob());
                            formData.append("path", `/posts/${uuidv4()}/${blobInfo.filename()}`);
                            const data = await uploadFile(formData)

                            resolve(data.data[0].Location);
                        });
                    },
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                    ],
                    toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "image" +
                        "removeformat | help",
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }
                }
            />
        </div>
    )
}