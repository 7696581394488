import { useQuery } from '@tanstack/react-query';
import {  Service, getServices } from './toprent-api';

export const ORDER_QUERY_KEY = 'order';

export const useServicesQuery = () => {
    return useQuery<Service[]>({
        queryKey: ['get-services'],
        queryFn: async () => {
            const response = await getServices();

            return response.data.services;
        },
    });
};
