import { FC, useEffect, useState } from "react";
import { useAuthProfileQuery } from "../api/use-auth-profile-query";
import { useLocation } from "react-router-dom";
import { Button, Card, List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import {
    PresentationChartBarIcon,
    InboxIcon,
    CubeIcon,
    NewspaperIcon,
  } from "@heroicons/react/24/solid";
import { Link, Outlet } from "react-router-dom";

export const MainLayout: FC = () => {
    const [opened, setOpened] = useState(false);
    const { data: profile, isLoading } = useAuthProfileQuery();

    const location = useLocation();

    useEffect(() => {
        setOpened(false);
    }, [location]);

    if (isLoading) return <div>Загрузка профиля</div>;

    if (!profile || profile.role !== 'ADMIN') window.location.href = "https://toprent.ru";

    return (
        <div>
            <div className="p-4 xl:flex justify-end hidden">
                <Button onClick={() => setOpened(true)}>Меню</Button>
            </div>
            <div className="flex p-4">
                <Card className={`${!opened ? 'xl:hidden' : ''} xl:fixed xl:z-30 xl:top-0 xl:left-0 xl:w-full xl:h-full`}>
                    <div className="mb-2 p-4 flex justify-between">
                        <Typography variant="h5" color="blue-gray">
                            TopRent
                        </Typography>

                        <div className="hidden xl:block">
                            <Button onClick={() => setOpened(false)}>Закрыть</Button>
                        </div>
                    </div>
                    <List>
                        <Link to="/">
                            <ListItem>
                                <ListItemPrefix>
                                    <PresentationChartBarIcon className="h-5 w-5" />
                                </ListItemPrefix>
                                Главная
                            </ListItem>
                        </Link>
                        <Link to="/models">
                            <ListItem>
                                <ListItemPrefix>
                                    <InboxIcon className="h-5 w-5" />
                                </ListItemPrefix>
                                Модели
                            </ListItem>
                        </Link>
                        <Link to="/orders">
                            <ListItem>
                                <ListItemPrefix>
                                    <CubeIcon className="h-5 w-5" />
                                </ListItemPrefix>
                                Заказы
                            </ListItem>
                        </Link>
                        <Link to="/posts">
                            <ListItem>
                                <ListItemPrefix>
                                    <NewspaperIcon className="h-5 w-5" />
                                </ListItemPrefix>
                                Блог
                            </ListItem>
                        </Link>
                    </List>
                </Card>
                <Card className="flex-1 ml-8 xl:ml-0">
                    <div className="flex-1">
                        <Outlet />
                    </div>
                </Card>
            </div>
        </div>
    )
}