import { Langs, Transmission, AllowedPlace, WheelDrive, CarsModelStatus, CarsModelTarget } from "../api/toprent-api";

export const TRANSMISSIONS: Record<Transmission, string> = {
    AUTO: "Автоматическая",
    MANUAL: "Ручная"
}

export const ALLOWED_PLACE: Record<AllowedPlace, string> = {
    CITY: 'Город',
    MOUNTAIN: 'Горы',
    ASPLHALT: 'Асфальт',
}

export const WHEEL_DRIVE: Record<WheelDrive, string> = {
    FRONT: "Передний",
    BACK: "Задний",
    FULL: "Полный"
}

export const STATUS: Record<CarsModelStatus, string> = {
    CREATED: "Создан",
    PUBLISHED: "Опубликован",
    DELETED: "Удален"
}

export const TARGETS: Record<CarsModelTarget, string> = {
    EVERY_DAY: "На каждый день",
    BUSINESS: "Бизнес",
    MOUNTAIN: "Поездка в горы",
    MEETING: "Свидание"
}

export const LANGS: Langs[] = ['ru', 'en']