import { useQuery } from '@tanstack/react-query';
import {  Service, getPlaces } from './toprent-api';

export const ORDER_QUERY_KEY = 'order';

export const usePlacesQuery = () => {
    return useQuery<Service[]>({
        queryKey: ['get-places'],
        queryFn: async () => {
            const response = await getPlaces();

            return response.data.places;
        },
    });
};
