import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack'
import { CarsModel, ReqCarsModel, updateCarsModel } from './toprent-api';

export const useUpdateModelsCarMutation = (id: string) => {
    const qc = useQueryClient()
    return useMutation({
        mutationFn: async (carsModel: ReqCarsModel): Promise<CarsModel> => {
            const { data } = await updateCarsModel(id, carsModel);

            return data;
        },
        onSuccess: async () => {
            qc.invalidateQueries({ queryKey: ['get-cars-model', id] })
            enqueueSnackbar({ variant: 'success', message: 'Модель обновлена' })
        },
        onError: () => {
            enqueueSnackbar({ variant: 'error', message: 'Ошибка при сохранении' })
        }
    });
};
