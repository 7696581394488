import { useQuery } from '@tanstack/react-query';
import {  CarsModel, getCarsModels } from './toprent-api';

export const ORDER_QUERY_KEY = 'order';

export const useCarsModelsQuery = () => {
    return useQuery<CarsModel[]>({
        queryKey: ['get-cars-models'],
        queryFn: async () => {
            const response = await getCarsModels();

            return response.data;
        },
    });
};
