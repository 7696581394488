import { useQuery } from '@tanstack/react-query';
import {  OrderResponse, OrdersParams, getOrders } from './toprent-api';

export const ORDER_QUERY_KEY = 'order';

export const useOrdersQuery = (params: OrdersParams) => {
  return useQuery<OrderResponse>({
    queryKey: ['get-orders', JSON.stringify(params)],
    queryFn: async () => {
      const response = await getOrders(params);

      return response.data;
    },
  });
};
