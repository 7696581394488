import { FC } from "react";
import { PageLayout } from "../../layouts/page-layout";
import { useParams } from "react-router-dom";
import { useCarsModelQuery } from "../../api/use-cars-model-query";
import { Button, Progress, Typography } from "@material-tailwind/react";
import { ModelForm } from "../components/model-form";
import { ReqCarsModel } from "../../api/toprent-api";
import { useUpdateModelsCarMutation } from "../../api/use-update-cars-model-mutation";
import { CarsModal } from "./components/cars-modal";
import { Uploader } from "../../components/uploader";
import { FilesList } from "../../components/files-list";
import { useCrmCarsQuery } from "../../api/use-crm-cars-query";
import { carMapToDto } from "../../utils/car-map-to-dto";

const TABLE_HEAD = ["ID", "Название", "Госнормер", ""];

export const Model: FC = () => {
    const { id } = useParams()
    
    const { data: model, isLoading } = useCarsModelQuery(id as string)
    const { data: carsCrm } = useCrmCarsQuery();
    const { mutate: updateModel, isPending } = useUpdateModelsCarMutation(id!)
    if (isLoading || !model) return <Progress/>

    const handleSubmit = async (values: ReqCarsModel) => {
        updateModel(carMapToDto(values))
    }

    const removeCar = (carId: number) => {
        updateModel({ cars: model.cars.filter((item) => item !== carId) })
    }

    const resCars = carsCrm?.filter((item) => model.cars?.includes(item.carId));

    return (
        <PageLayout title={model?.name.ru}>
            <ModelForm initialValues={model} onSubmit={handleSubmit} isLoading={isPending} submitContent="Сохранить" isUpdate updateForm={(
                <>
                    <table className="w-full min-w-max table-auto text-left mt-8">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                            <th
                                key={head}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                                >
                                {head}
                                </Typography>
                            </th>
                            ))}
                        </tr>
                    </thead>
                    {!resCars?.length && (
                        <div>Добавленных авто нет</div>
                    )}
                    {resCars && (
                        <tbody>
                        {resCars.map(({ _id, carId, model, number }, index) => {
                            const isLast = index === resCars.length - 1;
                            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                
                            return (
                            <tr key={carId}>
                                <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                        {carId}
                                    </Typography>
                                </td>
                                <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                        {model}
                                    </Typography>
                                </td>
                                <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                        {number}
                                    </Typography>
                                </td>
                                <td className={classes}>
                                    <Button color="red" onClick={() => removeCar(carId)}>
                                        Удалить
                                    </Button>
                                </td>
                            </tr>
                            );
                        })}
                        </tbody>
                    )}
                </table>
                <CarsModal modelId={id!} existsCar={model.cars} />
                <div className="mt-8">
                    <FilesList files={model.images} onUpdate={(files) => updateModel({ ...model, images: files })} />
                    <div className="mt-4">
                        <Uploader path={`/cars/${id}/`} modelId={model._id} onUpload={(files) => updateModel({ ...model, images: [ ...(model.images || []), ...files] })} />
                    </div>
                </div>
            </>
            )} />
        </PageLayout>
    )
}