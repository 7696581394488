import { useMutation, useQueryClient } from '@tanstack/react-query';
import { File, deleteFile, uploadFile } from './toprent-api';

export const useUploadFileMutation = (modelId: string) => {
    const qc = useQueryClient()

    return useMutation({
        mutationFn: async (data: FormData): Promise<File[]> => {
            const { data: files } = await uploadFile(data);

            return files;
        },
        onSuccess: async () => {
            qc.invalidateQueries({ queryKey: ['get-cars-model', modelId] })
        }
    });
};

export const useDeleteFileMutation = () => {
    return useMutation({
        mutationFn: async (path: string): Promise<boolean> => {
            const { data } = await deleteFile(path);

            return data;
        },
    });
};
