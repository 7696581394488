import { Typography } from "@material-tailwind/react";
import { FC, ReactNode } from "react";

export type PageLayoutProps = {
    children: ReactNode;
    actions?: ReactNode;
    title?: string;
}

export const PageLayout: FC<PageLayoutProps> = ({ children, title, actions }) => {
    return (
        <div className="p-6">
            <div className="flex justify-between">
                <Typography variant="h1">{title}</Typography>
                {actions}
            </div>
            <div className="mt-6">{children}</div>
        </div>
    )
};
