import { FC } from "react";
import { Typography } from "@material-tailwind/react";
import {
    ChevronRightIcon,
    ChevronLeftIcon,
    TrashIcon
  } from "@heroicons/react/24/solid";
import { useDeleteFileMutation } from "../api/use-files";
import { File } from "../api/toprent-api";
import { arrayMove } from "../utils/array-move";

export type FilesListProps = {
    files: File[];
    onUpdate?: (files: File[]) => void;
}

export const FilesList: FC<FilesListProps> = ({ files, onUpdate = () => {} }) => {
    const { mutateAsync: deleteFile } = useDeleteFileMutation();

    const handleDelete = async (path: string) => {
        try {
            const res = await deleteFile(path)
            if (res) {
                onUpdate(files.filter((item) => item.Key !== path));
            }
        } catch {}
    }

    return (
        <div>
            <Typography variant="h3">
                Изображения
            </Typography>
            <div className="mt-4">
                {!files.length && <div>Нет добавленных файлов</div>}
                <div className="grid grid-cols-5 gap-4">
                    {files.map(({ Location, Key }, key) => (
                        <div className="flex justify-center items-center relative" key={Location}>
                            <div className="cursor-pointer absolute right-0 top-0 p-2 bg-black text-white rounded-md" onClick={() => handleDelete(Key)}>
                                <TrashIcon className="h-5 w-5" color="white" />
                            </div>
                            {key !== 0 && (
                                <div className="cursor-pointer absolute left-2 bottom-0 m-auto top-0 h-9 w-9 flex items-center justify-center bg-black text-white rounded-md" onClick={() => onUpdate(arrayMove<File>(files, key, key - 1))}>
                                    <ChevronLeftIcon className="h-5 w-5" color="white" />
                                </div>
                            )}
                            {key !== files.length -1 && (
                                <div className="cursor-pointer absolute right-2 bottom-0 m-auto top-0 h-9 w-9 flex items-center justify-center bg-black text-white rounded-md" onClick={() => onUpdate(arrayMove<File>(files, key, key + 1))}>
                                    <ChevronRightIcon className="h-5 w-5" color="white" />
                                </div>
                            )}
                            <img src={Location} alt="" className="max-h-full max-w-full" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};
