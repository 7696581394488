import { useQuery } from '@tanstack/react-query';
import { Post, getPost } from './toprent-api';

export const usePostQuery = (id: string) => {
    return useQuery<Post>({
        queryKey: ['get-post', id],
        queryFn: async () => {
            const response = await getPost(id);

            return response.data;
        },
    });
};
