import { FC, ReactNode } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import slug from 'slug';
import { Button, Input, Select, Typography, Option } from "@material-tailwind/react";
import { Post } from "../../api/toprent-api";
import { Preview } from "./preview";
import { TextEdit } from "./text-edit";

type PostsFormProps = {
    initialValues: Post;
    isEdit?: boolean;
    onSubmit: (post: Post) => void;
}

const SignupSchema = Yup.object().shape({
    title: Yup.string()
      .required('Обязательное поле'),
    slug: Yup.string()
      .required('Обязательное поле'),
});

const FormItem: FC<{ title: string; children: ReactNode }> = ({ title, children }) => (
    <div className="w-full">
        <Typography variant="h6" color="blue-gray" className="-mb-3">
            {title}
        </Typography>
        <div className="mt-4">
            {children}
        </div>
    </div>
)

export const PostsForm: FC<PostsFormProps> = ({ initialValues, isEdit, onSubmit }) => {
    const formik = useFormik<Post>({
        initialValues,
        validationSchema: SignupSchema,
        onSubmit,
    });

    const handleChangeTitle = (title: string) => {
        formik.setFieldValue('slug', slug(title));
        formik.setFieldValue('title', title);
    }

    return (
        <div>
            <form onSubmit={formik.handleSubmit} className="w-full max-w-[900px] m-auto flex flex-col gap-4">
                <FormItem title="Заголовок">
                    <Input
                        size="lg"
                        value={formik.values.title}
                        name="title"
                        onChange={(e) => handleChangeTitle(e.target.value)}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        error={!!formik.errors.title}
                        crossOrigin=""
                    />
                </FormItem>
                
                <FormItem title="Ссылка">
                    <Input
                        size="lg"
                        value={formik.values.slug}
                        name="slug"
                        disabled={isEdit}
                        onChange={formik.handleChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        error={!!formik.errors.slug}
                        crossOrigin=""
                    />
                </FormItem>

                <FormItem title="Время поездки">
                    <div className="flex gap-4">
                        <FormItem title="Часы">
                            <Input
                                size="lg"
                                value={formik.values.time?.hours}
                                name="time.hours"
                                type="number"
                                onChange={formik.handleChange}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.time}
                                crossOrigin=""
                            />
                        </FormItem>
                        <FormItem title="Минуты">
                            <Input
                                size="lg"
                                value={formik.values.time?.minutes}
                                name="time.minutes"
                                type="number"
                                onChange={formik.handleChange}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.time}
                                crossOrigin=""
                            />
                        </FormItem>
                    </div>
                </FormItem>
                <FormItem title="Дистанция поездки в км.">
                    <Input
                        size="lg"
                        value={formik.values.distance}
                        name="distance"
                        type="number"
                        onChange={formik.handleChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        error={!!formik.errors.distance}
                        crossOrigin=""
                    />
                </FormItem>

                <Preview onUpload={(value) => formik.setFieldValue('preview', value)} onRemove={() => formik.setFieldValue('preview', undefined)} value={formik.values.preview} />

                <TextEdit onChange={(value) => formik.setFieldValue('content', value)} value={formik.values.content} />

                <FormItem title="Яндекс карта">
                    <Input
                        size="lg"
                        value={formik.values.maps}
                        name="maps[0]"
                        onChange={formik.handleChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        error={!!formik.errors.maps}
                        crossOrigin=""
                    />
                </FormItem>

                <FormItem title="Статус">
                    <Select
                        size="lg"
                        value={formik.values.status}
                        name="status"
                        onChange={(e) => formik.setFieldValue('status', e)}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900 mt-3"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        error={!!formik.errors.status}
                    >
                        <Option value="DRAFT">
                            Черновик
                        </Option>
                        <Option value="PUBLISHED">
                            Опубликован
                        </Option>
                    </Select>
                </FormItem>

                <Button className="mt-8" color="green" fullWidth type="submit">{isEdit ? 'Сохранить' : 'Создать'}</Button>
            </form>
        </div>
    );
}