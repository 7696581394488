import { useQuery } from '@tanstack/react-query';
import {  Post, getPosts } from './toprent-api';

export const ORDER_QUERY_KEY = 'order';

export const usePostsQuery = () => {
    return useQuery<Post[]>({
        queryKey: ['get-posts'],
        queryFn: async () => {
            const response = await getPosts();

            return response.data;
        },
    });
};
