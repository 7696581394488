import { FC } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { v4 as uuidv4 } from 'uuid';
import { uploadFile } from "../../api/toprent-api";

type PreviewProps = {
    onUpload: (image: string) => void;
    onRemove?: () => void;
    value?: string;
}

export const Preview: FC<PreviewProps> = ({ onUpload, value, onRemove }) => {
    const handleUploadPreview = async (e: any) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("files", file);
        formData.append("path", `/posts/${uuidv4()}/${uuidv4()}.${file.type}`);
        const data = await uploadFile(formData)

        onUpload(data.data[0].Location);
    }

    return (
        <label className="border-dashed relative border-2 border-gray-500 flex justify-center items-center rounded-xl cursor-pointer h-40 bg-cover bg-center" style={{ backgroundImage: `url(${value})` }}>
            {value && (
                <XMarkIcon onClick={onRemove} className="h-8 w-8 absolute right-0 top-0 text-white bg-black"/>
            )}
            <span className="bg-black text-white inline-flex px-2">Нажмите, чтобы загрузить превью</span>

            <input type="file" name="files" accept=".jpg" id="fileToUpload" hidden onChange={(e) => {
                handleUploadPreview(e);
                e.target.value = '';
            }} />
        </label>
    )
}