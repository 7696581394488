import React, { FC } from "react";
import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
 
export const Pagination: FC<{
    active: number;
    handleChange: (arg: number) => void;
    pagesCount?: number, 
}> = ({
    active,
    handleChange,
    pagesCount,
}) => {
    if (!pagesCount || pagesCount === 1) return null;
  const getItemProps = (index: number) =>
    ({
      variant: active === index ? "filled" : "text",
      color: "gray",
      onClick: () => handleChange(index),
    } as any);
 
  const next = () => {
    if (active === 5) return;
 
    handleChange(active + 1);
  };
 
  const prev = () => {
    if (active === 1) return;
 
    handleChange(active - 1);
  };
 
  return (
    <div className="flex items-center gap-4">
      <Button
        variant="text"
        className="flex items-center gap-2"
        onClick={prev}
        disabled={active === 1}
      >
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previous
      </Button>
      <div className="flex items-center gap-2">
        {Array.from(Array(pagesCount).keys()).map((item) => (
            <IconButton {...getItemProps(item + 1)} key={item}>{item + 1}</IconButton>
        ))}
      </div>
      <Button
        variant="text"
        className="flex items-center gap-2"
        onClick={next}
        disabled={active === 5}
      >
        Next
        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </Button>
    </div>
  );
}