import { FC } from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@material-tailwind/react";
import { PageLayout } from "../layouts/page-layout";
import { usePostsQuery } from "../api/use-posts-query";

const TABLE_HEAD = ["Заголовок", "статус", ""];

const STATUSES: any = {
    PUBLISHED: 'Опубликован',
    DRAFT: 'Черновик'
}

export const Posts: FC = () => {
    const { data: posts } = usePostsQuery()
    return (
        <PageLayout title="Блог" actions={
            <Link to="/posts/create">
                <Button>Создать</Button>
            </Link>
        }>
            <table className="w-full min-w-max table-auto text-left">
                <thead>
                <tr>
                    {TABLE_HEAD.map((head) => (
                    <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                    >
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                        >
                            {head}
                        </Typography>
                    </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {posts?.map(({ _id, title, status }, index) => {
                    return (
                        <tr key={title}>
                            <td>
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="p-2 font-bold"
                                >
                                    {title}
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                >
                                    {STATUSES[status]}
                                </Typography>
                            </td>
                            <td>
                                <Link to={`/posts/${_id}`}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-bold"
                                    >
                                        Редактировать
                                    </Typography>
                                </Link>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </PageLayout>
    )
}