import { useMutation } from '@tanstack/react-query';
import { Post, createPost } from './toprent-api';

export const useCreatePostMutation = () => {
    return useMutation({
        mutationFn: async (post: Post): Promise<Post> => {
            const { data } = await createPost(post);

            return data;
        }
    });
};
