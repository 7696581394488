import { useQuery } from '@tanstack/react-query';
import {  CarsModel, getCarsModel } from './toprent-api';

export const ORDER_QUERY_KEY = 'order';

export const useCarsModelQuery = (id: string) => {
    return useQuery<CarsModel>({
        queryKey: ['get-cars-model', id],
        queryFn: async () => {
            const response = await getCarsModel(id);

            return response.data;
        },
    });
};
