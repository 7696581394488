import { FC } from "react";
import { PageLayout } from "../../layouts/page-layout";
import { useParams } from "react-router-dom";
import { Post as IPost } from "../../api/toprent-api";
import { usePostQuery } from "../../api/use-post-query";
import { useUpdatePostMutation } from "../../api/use-update-post-mutation";
import { PostsForm } from "../components/posts-form";

export const Post: FC = () => {
    const { id } = useParams()
    
    const { data: post } = usePostQuery(id!)
    const { mutate: updatePost } = useUpdatePostMutation(id!)

    const handleSubmit = async (post: IPost) => {
        if (!post.preview) return;
        await updatePost(post)
    }
   
    if (!post) return null;

    return (
        <PageLayout title={post?.title}>
            <PostsForm
                onSubmit={handleSubmit}
                initialValues={post}
                isEdit
            />
        </PageLayout>
    )
}