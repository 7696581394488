import { FC, useState } from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Typography,
} from "@material-tailwind/react";
import { useCrmCarsQuery } from "../../../api/use-crm-cars-query";
import { Car } from "../../../api/toprent-api";
import { useUpdateModelsCarMutation } from "../../../api/use-update-cars-model-mutation";

const TABLE_HEAD = ["ID", "Название", "Госнормер", ""];

export const CarsModal: FC<{ modelId: string; existsCar: number[] }> = ({ modelId, existsCar }) => {
    const [open, setOpen] = useState(false);
    const { data: cars } = useCrmCarsQuery();
    const { mutate: updateModel, isPending } = useUpdateModelsCarMutation(modelId!)
    
    const handleOpen = () => setOpen(!open);

    const handleAdd = (car: Car) => {
        updateModel({ cars: [...existsCar, car.carId] })
    }
    
    return (
        <div>
            <Button onClick={handleOpen} variant="gradient" className="mt-8 w-full">
                Добавить авто
            </Button>

            <Dialog open={open} handler={handleOpen}>
                <DialogHeader>Выберите автомобиль из списка</DialogHeader>
                <DialogBody>
                    <div className="overflow-y-auto h-96 relative">
                        {isPending && (
                            <div className="absolute top-0 left-0 w-full h-full bg-white opacity-60" />
                        )}
                        <span>Всего: {cars?.length}</span>
                        <table className="w-full min-w-max table-auto text-left mt-2">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                <th
                                    key={head}
                                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                                >
                                    <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                    >
                                    {head}
                                    </Typography>
                                </th>
                                ))}
                            </tr>
                        </thead>
                        {!cars?.length && (
                            <div>Добавленных авто нет</div>
                        )}
                        {cars && (
                            <tbody>
                            {cars.map((item, index) => {
                                const { carId, model, number } = item;
                                const isLast = index === cars.length - 1;
                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                                const isExist = existsCar?.find((existItem) => existItem === carId);
                    
                                return (
                                <tr key={carId}>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {carId}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {model}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {number}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        {!isExist && (
                                            <Button
                                                onClick={() => handleAdd(item)}
                                                className="font-medium"
                                            >
                                                Добавить
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                                );
                            })}
                            </tbody>
                        )}
                    </table>
                </div>
                </DialogBody>
                <DialogFooter>
                
                <Button variant="gradient" color="green" onClick={handleOpen}>
                    <span>Готово</span>
                </Button>
                </DialogFooter>
            </Dialog>
        </div>
    )
}