import './App.css';
import { ThemeProvider } from "@material-tailwind/react";
import { MainLayout } from './layouts/main-layout';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Dashboard } from './dashboard/dashboard';
import { SnackbarProvider } from 'notistack'
import { Models } from './models/page';
import { Model } from './models/[id]/page';
import { ModelCreate } from './models/create/page';
import { Orders } from './orders/page';
import { Posts } from './posts/page';
import { PostsCreate } from './posts/create/page';
import { Post } from './posts/[id]/page';

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/models",
        element: <Models />,
      },
      {
        path: "/posts",
        element: <Posts />,
      },
      {
        path: "/posts/create",
        element: <PostsCreate />,
      },
      {
        path: "/posts/:id",
        element: <Post />,
      },
      {
        path: "/models/create",
        element: <ModelCreate />,
      },
      {
        path: "/models/:id",
        element: <Model />,
      },
      {
        path: "/orders",
        element: <Orders />,
      },
    ],
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider />
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
