import { FC, ReactNode } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import {
    XMarkIcon
  } from "@heroicons/react/24/solid";
import { Typography, Input, Button, Select, Option, Tab, Tabs, TabsHeader, TabsBody, TabPanel, Textarea } from "@material-tailwind/react";
import { ALLOWED_PLACE, LANGS, STATUS, TARGETS, TRANSMISSIONS, WHEEL_DRIVE } from "../constants";
import { CarsModelTarget, ReqCarsModel } from "../../api/toprent-api";
import { useServicesQuery } from "../../api/use-services-query";
import { useWashingQuery } from "../../api/use-washing-query";
import { usePlacesQuery } from "../../api/use-places-query";

const SignupSchema = Yup.object().shape({
    name: Yup.object().shape({
        ru: Yup.string()
        .required('Обязательное поле'),
    }),
    slug: Yup.string()
      .required('Обязательное поле'),
    transmission: Yup.string()
      .required('Обязательное поле'),
    description: Yup.string()
      .required('Обязательное поле'),
    wheelDrive: Yup.string()
      .required('Обязательное поле'),
    washing: Yup.number()
      .required('Обязательное поле'),
    allowedPlace: Yup.string()
      .required('Обязательное поле'),
    year: Yup.number()
      .required('Обязательное поле'),
    power: Yup.number()
      .required('Обязательное поле'),
    minPrice: Yup.number()
      .required('Обязательное поле'),
    minExperience: Yup.number()
      .required('Обязательное поле'),
    capacity: Yup.number()
      .required('Обязательное поле'),
    minAge: Yup.number()
      .required('Обязательное поле'),
  });

type ModelFormProps = {
    onSubmit: (values: ReqCarsModel) => void
    initialValues: ReqCarsModel
    isLoading?: boolean
    submitContent?: string
    updateForm?: ReactNode;
    isUpdate?: boolean;
}

const CustomList: FC<{ list: { label: string; value: string | number }[], value: (string | number)[], onClick: (value: string | number) => void }> = ({ list, value, onClick }) => {
    return (
        <div className="flex flex-wrap">
            {list.map((item) => {
                const selected = value?.includes(item.value);
                return (
                    <div key={item.label} onClick={() => onClick(item.value)} className={`flex p-2 border rounded-md mr-2 mb-2 hover:cursor-pointer hover:opacity-70 items-center text-xs transition-all ${selected ? 'bg-gray-200' : ''}`}>
                        {selected && (
                            <XMarkIcon className="h-5 w-5 mr-4"/>
                        )}
                        {item.label}
                    </div>
            )})}
        </div>
    )
}

const FormItem: FC<{ title: string; children: ReactNode }> = ({ title, children }) => (
    <div>
        <Typography variant="h6" color="blue-gray" className="-mb-3">
            {title}
        </Typography>
        <div className="mt-4">
            {children}
        </div>
    </div>
)

export const ModelForm: FC<ModelFormProps> = ({ isUpdate, onSubmit, initialValues, isLoading, submitContent = 'Создать', updateForm }) => {
    const formik = useFormik<ReqCarsModel>({
        validationSchema: SignupSchema,
        initialValues,
        onSubmit,
    });

    const { data: services } = useServicesQuery();
    const { data: whashingList } = useWashingQuery();
    const { data: places } = usePlacesQuery();

    const handleChangeScope = (scope: number) => {
        if (formik.values.scope?.includes(scope)) {
            formik.setFieldValue('scope', formik.values.scope?.filter((item) => item !== scope))
            return;
        }

        formik.setFieldValue('scope', [...(formik.values.scope || []), scope])
    }
    
    const handleChangeTargets = (target: CarsModelTarget) => {
        if (formik.values.targets?.includes(target)) {
            formik.setFieldValue('targets', formik.values.targets?.filter((item) => item !== target))
            return;
        }

        formik.setFieldValue('targets', [...(formik.values.targets || []), target])
    }
    
    const handleChangePlaces = (place: number) => {
        if (formik.values.places?.includes(place)) {
            formik.setFieldValue('places', formik.values.places?.filter((item) => item !== place))
            return;
        }

        formik.setFieldValue('places', [...(formik.values.places || []), place])
    }

    const getCostType = (serviceTypeId: number) => {
        if (serviceTypeId === 2) return `₽ / сутки`;
        if (serviceTypeId === 3) return '%'
        return '₽'
      }

    return (
        <form onSubmit={formik.handleSubmit} className="relative">
                {isLoading && (
                    <div className="absolute top-0 left-0 w-full h-full bg-white opacity-50" />
                )}
                <div className="mb-1 grid grid-cols-2 gap-6">
                    <div className="flex gap-5 flex-col">
                        <FormItem title="Название модели">
                            <Tabs value="ru">
                                    <TabsHeader>
                                        {LANGS.map((item) => (
                                            <Tab value={item} key={item}>
                                                {item}
                                            </Tab>
                                        ))}
                                    </TabsHeader>
                                    <TabsBody>
                                        {LANGS.map((item) => (
                                            <TabPanel value={item} key={item} className="p-0 mt-4"> 
                                                <Input
                                                    size="lg"
                                                    value={formik.values.name?.[item]}
                                                    name={`name.${item}`}
                                                    onChange={formik.handleChange}
                                                    placeholder="Mercedes-Benz E-class"
                                                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                                    labelProps={{
                                                        className: "before:content-none after:content-none",
                                                    }}
                                                    error={!!formik.errors.name}
                                                    crossOrigin=""
                                                />
                                            </TabPanel>
                                        ))}
                                    </TabsBody>
                                </Tabs>
                        </FormItem>

                        <FormItem title="Ссылка">
                            <Input
                                size="lg"
                                value={formik.values.slug}
                                name="slug"
                                disabled={isUpdate}
                                onChange={formik.handleChange}
                                placeholder="mercedes-benz-e-class-w212"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.slug}
                                crossOrigin=""
                            />
                        </FormItem>
                       
                        <FormItem title="Описание">
                            <Textarea
                                size="lg"
                                value={formik.values.description}
                                name="description"
                                onChange={formik.handleChange}
                                placeholder="Описание"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.description}
                            />
                        </FormItem>

                        <FormItem title="Год выпуска">
                            <Input
                                size="lg"
                                value={formik.values.year}
                                name="year"
                                type="number"
                                onChange={formik.handleChange}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.year}
                                crossOrigin=""
                            />
                        </FormItem>

                        <FormItem title="Мощность л/с">
                            <Input
                                size="lg"
                                value={formik.values.power}
                                name="power"
                                type="number"
                                onChange={formik.handleChange}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.power}
                                crossOrigin=""
                            />
                        </FormItem>

                        <FormItem title="Минимальная цена">
                            <Input
                                size="lg"
                                value={formik.values.minPrice}
                                name="minPrice"
                                type="number"
                                icon="₽"
                                onChange={formik.handleChange}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.minPrice}
                                crossOrigin=""
                            />
                        </FormItem>
                    
                       <FormItem title="Минимальный стаж">
                            <Input
                                size="lg"
                                value={formik.values.minExperience}
                                name="minExperience"
                                type="number"
                                icon="лет"
                                onChange={formik.handleChange}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.minExperience}
                                crossOrigin=""
                            />
                       </FormItem>
                        
                        <FormItem title="Минимальный возраст">
                            <Input
                                size="lg"
                                value={formik.values.minAge}
                                name="minAge"
                                type="number"
                                icon="лет"
                                onChange={formik.handleChange}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.minAge}
                                crossOrigin=""
                            />
                        </FormItem>
                        
                        <FormItem title="Расход топлива / 100км">
                            <Input
                                size="lg"
                                value={formik.values.consumption || undefined}
                                name="consumption"
                                type="number"
                                step="any"
                                icon="л."
                                onChange={formik.handleChange}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.consumption}
                                crossOrigin=""
                            />
                        </FormItem>
                        
                        <FormItem title="Объем двигателя">
                            <Input
                                step="any"
                                size="lg"
                                value={formik.values.engineCapacity || undefined}
                                name="engineCapacity"
                                type="number"
                                icon="л."
                                onChange={formik.handleChange}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.engineCapacity}
                                crossOrigin=""
                            />
                        </FormItem>
                        
                        <FormItem title="Кол-во мест">
                            <Input
                                size="lg"
                                value={formik.values.capacity}
                                name="capacity"
                                type="number"
                                onChange={formik.handleChange}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.capacity}
                                crossOrigin=""
                            />
                        </FormItem>
                        
                        <FormItem title="Разрешенное место для вождения">
                            <Select
                                size="lg"
                                value={formik.values.allowedPlace}
                                name="allowedPlace"
                                onChange={(e) => formik.setFieldValue('allowedPlace', e)}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.allowedPlace}
                            >
                                {Object.entries(ALLOWED_PLACE).map(([key, value]) => (
                                    <Option value={key} key={key}>
                                        {value}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>

                        <FormItem title="Трансмиссия">
                            <Select
                                size="lg"
                                value={formik.values.transmission}
                                name="transmission"
                                onChange={(e) => formik.setFieldValue('transmission', e)}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.transmission}
                            >
                                {Object.entries(TRANSMISSIONS).map(([key, value]) => (
                                    <Option value={key} key={key}>
                                        {value}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>

                        <FormItem title="Мойка">
                            {whashingList?.length && (
                                <Select
                                    size="lg"
                                    value={`${formik.values.washing}`}
                                    name="washing"
                                    onChange={(e) => formik.setFieldValue('washing', Number(e))}
                                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                    labelProps={{
                                        className: "before:content-none after:content-none",
                                    }}
                                    error={!!formik.errors.washing}
                                >
                                    {whashingList?.map(({ service_id, title }) => (
                                        <Option value={`${service_id}`} key={service_id}>
                                            {title}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </FormItem>

                        <FormItem title="Привод">
                            <Select
                                size="lg"
                                value={formik.values.wheelDrive}
                                name="wheelDrive"
                                onChange={(e) => formik.setFieldValue('wheelDrive', e)}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.wheelDrive}
                            >
                                {Object.entries(WHEEL_DRIVE).map(([key, value]) => (
                                    <Option value={key} key={key}>
                                        {value}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>

                       <FormItem title="Статус">
                            <Select
                                size="lg"
                                value={formik.values.status}
                                name="status"
                                onChange={(e) => formik.setFieldValue('status', e)}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                error={!!formik.errors.status}
                            >
                                {Object.entries(STATUS).map(([key, value]) => (
                                    <Option value={key} key={key}>
                                        {value}
                                    </Option>
                                ))}
                            </Select>
                       </FormItem>
                    </div>

                    <div className="flex flex-col gap-4">
                        <FormItem title="Опции">
                            {services && (
                                <CustomList
                                    list={services.map(({ service_id, cost, title, service_type_id }) => ({ label: `${title} - ${cost} ${getCostType(service_type_id)}`, value: service_id }))}
                                    value={formik.values.scope as number[]}
                                    onClick={(value) => handleChangeScope(value as number)}
                                />
                            )}
                        </FormItem>

                        <FormItem title="Место получения/возврата">
                            {places && (
                                <CustomList
                                    list={places.map(({ cost, service_id, title, service_type_id }) => ({ label: `${title} - ${cost} ${getCostType(service_type_id)}`, value: service_id, }))}
                                    onClick={(value) => handleChangePlaces(value as number)}
                                    value={formik.values.places as number[]}
                                />
                            )}
                        </FormItem>
                     
                        <FormItem title="Цели">
                            <CustomList
                                list={Object.entries(TARGETS).map(([value, label]) => ({ value, label }))}
                                value={formik.values.targets as string[]}
                                onClick={(value) => handleChangeTargets(value as CarsModelTarget)}
                            />
                        </FormItem>

                        {updateForm}
                    </div>
                </div>

                <Button className="mt-8" color="green" fullWidth type="submit">{submitContent}</Button>
            </form>
    )
};