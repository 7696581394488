import axios, { AxiosResponse } from 'axios';

function clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }
  

export type Transmission = 'AUTO' | 'MANUAL';

export type AllowedPlace = 'CITY' | 'MOUNTAIN' | 'ASPLHALT';

export type WheelDrive = 'FRONT' | 'BACK' | 'FULL';
export type Langs = 'ru' | 'en';
export type CarsModelStatus = 'CREATED' | 'PUBLISHED' | 'DELETED';
export type CarsModelTarget = 'EVERY_DAY' | 'BUSINESS' | 'MOUNTAIN' | 'MEETING';

export type ReqCarsModel = {
    name?: Record<Langs, string>;
    colors?: string[];
    slug?: string;
    transmission?: Transmission;
    washing?: number;
    description?: string;
    wheelDrive?: WheelDrive;
    year?: number;
    cars?: number[];
    power?: number;
    consumption?: number | null;
    engineCapacity?: number | null;
    allowedPlace?: AllowedPlace;
    capacity?: number;
    places?: number[];
    status?: CarsModelStatus;
    minPrice?: number;
    images?: File[];
    targets?: CarsModelTarget[];
    minExperience?: number;
    minAge?: number;
    scope?: number[];
}

export const queryCarsModel = {
    post: (data: ReqCarsModel) => axios.post('/api/cars', data)
}

export type AuthProfile = {
    _id: string;
    email: string;
    role: 'CLIENT' | 'ADMIN';
}

export type CarsModel = {
    _id: string;
    slug: string;
    name: Record<Langs, string>;
    colors: string[];
    transmission: Transmission;
    wheelDrive: WheelDrive;
    year: number;
    cars: number[];
    images: File[];
    power: number;
    description: string;
    consumption?: number | null;
    engineCapacity?: number | null;
    minPrice: number;
    minExperience: number;
    status: CarsModelStatus;
    scope: number[];
}

export type Service = {
    service_id: number;
    title: string;
    cost: number;
    service_type_id: number;
    archive: boolean;
}

export type Place = {
    place_id: number;
    title: string;
    delivery_cost: number;
}

export type CrmCar = {
    car_id: number;
    model: string;
    modelId: string;
    modelSlug: string;
    number: string;
    city_id: number;
    year: number;
}

export type Car = {
    _id?: string;
    carId: number;
    model: string;
    modelId: string;
    number: string;
    cityId: number;
    year: number;
}

export type File = {
    ETag: string,
    Location: string,
    Key: string,
    Bucket: string
}

export type Post = {
    title: string;
    slug: string;
    time?: { hours?: string; minutes?: number; };
    maps?: string[];
    content: string;
    lang: string;
    distance?: number;
    _id?: string;
    preview: string;
    status: string;
}

export type OrderStatus = "CREATED" | "PAID" | "CANCELED";

export type Order = {
    _id: string;
    carId: number;
    cost: number; // стоимость итог
    rentCost: number; // стоимость брони
    prepayment: number; // сумма предоплаты
    deposit: number; // залог
    userId: string;
    begin: string;
    bidId: number;
    bidNumber: number;
    end: string;
    beginPlaceId: number;
    endPlaceId: number;
    name: string;
    surname: string;
    phone: string;
    services: { name: string; cost: number; }[];
    status: OrderStatus;
    comment?: string;
}

export type OrderResponse = {
    listCount: number;
    pagesCount: number;
    list: Order[];
}

export type OrdersParams = {
    page?: string;
    bidNumber?: string;
}

export const getAuthProfile = (): Promise<AxiosResponse<AuthProfile>> => axios.get('/api/auth/profile');

export const getCarsModels = (): Promise<AxiosResponse<CarsModel[]>> => axios.get('/api/cars-models/full');

export const getCarsModel = (id: string): Promise<AxiosResponse<CarsModel>> => axios.get(`/api/cars-models/${id}`);

export const getCars = (modelId: string): Promise<AxiosResponse<Car[]>> => axios.get(`/api/cars?modelId=${modelId}`);

export const createCarsModel = (data: ReqCarsModel): Promise<AxiosResponse<CarsModel>> => axios.post('/api/cars-models', data);

export const createCar = (data: Car): Promise<AxiosResponse<Car>> => axios.post('/api/cars', data);

export const createPost = (data: Post): Promise<AxiosResponse<Post>> => axios.post('/api/posts', data);

export const cancelOrder = (orderId: string): Promise<AxiosResponse<Order>> => axios.post('/api/orders/cancel/' + orderId);

export const updatePost = (id: string, data: Post): Promise<AxiosResponse<Post>> => axios.put('/api/posts/' + id, data);

export const getPosts = (): Promise<AxiosResponse<Post[]>> => axios.get('/api/posts');
export const getPost = (id: string): Promise<AxiosResponse<Post>> => axios.get('/api/posts/' + id);

export const removeCar = (carId: string): Promise<AxiosResponse<Car>> => axios.delete(`/api/cars/${carId}`);

export const updateCarsModel = (id: string, data: ReqCarsModel): Promise<AxiosResponse<CarsModel>> => axios.put(`/api/cars-models/${id}`, data);

export const getServices = (): Promise<AxiosResponse<{ services: Service[] }>> => axios.get(`/api/crm/services`);

export const getWashing = (): Promise<AxiosResponse<{ services: Service[] }>> => axios.get(`/api/crm/washing`);

export const getPlaces = (): Promise<AxiosResponse<{ places: Service[] }>> => axios.get(`/api/crm/places`);

export const getCrmCars = (): Promise<AxiosResponse<{ cars: CrmCar[] }>> => axios.get(`/api/crm/cars`);

export const uploadFile = (data: FormData): Promise<AxiosResponse<File[]>> => axios.post(`/api/nf`, data);

export const deleteFile = (path: string): Promise<AxiosResponse<boolean>> => axios.delete(`/api/nf?path=${path}`);

export const getOrders = (params: OrdersParams): Promise<AxiosResponse<OrderResponse>> => axios.get(`/api/orders/admin`, { params: clean(params) });